import { endpointGraphql } from "@nilo/design/src/utils";
import { saveSession, clearSession } from "../utils/cookies";

const query = `mutation RefreshToken($input: RefreshTokenInput!) {
    refreshToken(input: $input) {
        __typename
        accessToken
        idToken
        expiresIn
    }
  }`;

const headers = {
  "Content-Type": "application/json",
  "Access-Control-Allow-Origin": "*"
};

const getBody = (username: string, userSub: string, refreshToken: string) => {
  return JSON.stringify({
    query,
    variables: {
      input: {
        username,
        userSub,
        refreshToken,
        origin:
          process.env.NODE_ENV !== "production"
            ? process.env.NEXT_PUBLIC_ORIGIN
            : undefined
      }
    }
  });
};

const errorResolver = () => {
  clearSession();
  window.location.reload();
};

const resolverIdToken = ({ data, errors }, refreshToken) => {
  if (errors || !data) errorResolver();
  const { idToken } = data.refreshToken;
  saveSession({ ...data.refreshToken, refreshToken });
  return idToken;
};

const endpoint = endpointGraphql;

export const doRefreshToken = ({ refreshToken, username, userSub }) => {
  return fetch(endpoint, {
    method: "POST",
    headers,
    body: getBody(username, userSub, refreshToken)
  })
    .then((response) => response.json())
    .then((data) => {
      return resolverIdToken(data, refreshToken);
    })
    .catch((error) => {
      console.error(error);
    });
};
