"use client";

import { FlagProvider } from "@unleash/nextjs";
import { CustomContextProperties } from "@nilo/constants";
import { useProfileStore } from "../stores/profile-store";
import { unleashApiToken, unleashEnvironment } from "@nilo/design/src/utils";

export default function FeatureProvider({
  children
}: {
  children: React.ReactNode;
}) {
  const { user } = useProfileStore();

  return (
    <FlagProvider
      key={user.id}
      config={{
        appName: "Default",
        refreshInterval: 60,
        context: {
          properties: {
            supplierId: user.supplierId?.toString(),
            userId: user.id?.toString()
          } satisfies CustomContextProperties
        },
        environment: unleashEnvironment,
        url: process.env.NEXT_PUBLIC_UNLEASH_API_URL!,
        customHeaders: {
          Authorization: unleashApiToken
        }
      }}
      startClient={!!user.id}
    >
      {children}
    </FlagProvider>
  );
}
