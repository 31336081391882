"use client";

import { NiloSpinner } from "@nilo/design";
import { useFindConfigurationSite } from "../hooks/use-find-configuration";
import { useEffect, useState } from "react";
import { getSubdomain } from "@utils/*";
import { addManifest } from "../config/manifest";
import { useTranslations } from "next-intl";

export default function SiteProvider({
  children
}: {
  children: React.ReactNode;
}) {
  const [subdomain, setSubdomain] = useState<string>("");
  const t = useTranslations("eb2b");

  useEffect(() => {
    const _subdomain = getSubdomain(window.location.href);
    setSubdomain(_subdomain);
  }, []);
  const { loadConfiguration } = useFindConfigurationSite({ subdomain });

  useEffect(() => {
    if (loadConfiguration.isCookieLoaded) addManifest(loadConfiguration.data);
  }, [loadConfiguration.isCookieLoaded]);

  if (!loadConfiguration.isCookieLoaded)
    return (
      <div className="m-auto !h-[100dvh] h-[100vh]">
        <NiloSpinner message={t("configLoading")} />
      </div>
    );

  return <>{children}</>;
}
