/* eslint-disable prettier/prettier */
import { SiteConfiguration } from "@nilo/codegen";
import { manifestConfig } from "./build-manifest";

export const addManifest = async (site: SiteConfiguration) => {
    const config = await manifestConfig(site);
    const content = encodeURIComponent(JSON.stringify(config));
    const url = "data:application/manifest+json," + content;
    const element = document.createElement("link");
    element.setAttribute("rel", "manifest");
    element.setAttribute("href", url);
    document.querySelector("head").appendChild(element);
}

