"use client";
import { COOKIES } from "@nilo/constants";
import { setCookie, destroyCookie } from "nookies";

export const saveSession = async ({ expiresIn, idToken, refreshToken }) => {
  const value = JSON.stringify({ expiresIn, idToken, refreshToken });

  setCookie(null, COOKIES.session, value, {
    maxAge: 1000 * 60 * 60 * 24 * 300,
    path: "/",
    secure: process.env.NODE_ENV !== "production"
  });

  setTimeout(() => {
    return true;
  }, 3000);
};

export const saveUuidSession = async ({ uuid }) => {
  setCookie(null, COOKIES.uuid, uuid, {
    maxAge: 1000 * 60 * 60 * 24 * 300,
    path: "/",
    secure: process.env.NODE_ENV !== "production"
  });

  setTimeout(() => {
    return true;
  }, 3000);
};

export const clearSession = () => {
  destroyCookie(null, COOKIES.session, { path: "/" });
};
