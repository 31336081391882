import { MetadataRoute } from "next";
import { imagePath } from "@nilo/design/src/utils";
import { SiteConfiguration } from "@nilo/codegen";
import { getLocale } from "@nilo/design/src/i18n/utils";

const getPathIcons = (size: number, data: SiteConfiguration) =>
  `${imagePath}/fit-in/${size}x${size}/${data.icon}`;

const getHost = (): string => {
  return `${window.location.protocol}//${window.location.host}/`;
};

const buildManifest = (data: SiteConfiguration): MetadataRoute.Manifest => {
  return {
    name: data.name,
    short_name: data.name,
    start_url: `${getHost()}${getLocale()}/shopping`,
    scope: getHost(),
    background_color: "#f8fafc",
    theme_color: "#f8fafc",
    display: "standalone",
    orientation: "portrait",
    icons: [72, 128, 144, 192, 512].map((size: number) => ({
      src: getPathIcons(size, data),
      sizes: `${size}x${size}`,
      type: "image/png"
    }))
  };
};

export const manifestConfig = async (
  siteData: SiteConfiguration
): Promise<MetadataRoute.Manifest> => {
  const manifest: MetadataRoute.Manifest = buildManifest(siteData);
  return manifest;
};
