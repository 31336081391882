"use client";

import { COGNITO_USERNAME, COOKIES, UsernameParser } from "@nilo/constants";
import { parseCookies } from "nookies";
import { jwtDecode } from "jwt-decode";
import { doRefreshToken } from "./refresh-token";

const existSessionCookie = () => {
  const cookies = parseCookies();
  return !!cookies[COOKIES.session];
};

const getSession = () => {
  const cookies = parseCookies();
  return JSON.parse(cookies[COOKIES.session]);
}

const isNotExpired = (decodedToken) => {
  // token.exp is given in seconds 
  return decodedToken.exp && decodedToken.exp * 1000 > Date.now();
}

const refreshToken = (decodedToken, session) => {

  const cognitoUsername = decodedToken[COGNITO_USERNAME];

  const cognitoUsernameParsed = new UsernameParser(
    cognitoUsername
  );

  return doRefreshToken({
    username: cognitoUsernameParsed.getEmailOrPhone(),
    userSub: cognitoUsername,
    refreshToken: session.refreshToken
  });
}

export const getToken = async () => {
  if (!existSessionCookie()) return null;

  const session = getSession();

  const decodedToken = jwtDecode(session.idToken);
  if (!decodedToken) return null;

  if (isNotExpired(decodedToken)) return session.idToken;

  return refreshToken(decodedToken, session);
}

