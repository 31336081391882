"use client";

import { SiteConfiguration, useFindMySiteConfigurationQuery } from "@nilo/codegen";
import { useEffect, useContext, useState } from "react";
import { UIContext } from "@nilo/design";
import { parseCookies, setCookie } from "nookies";
import { COOKIES } from "@nilo/constants";
import { CACHE } from "@config";

interface Props {
  subdomain?: string;
}

interface State {
  site?: SiteConfiguration;
  isLoaded?: boolean;
  skip?: boolean;
}

export const useFindConfigurationSite = ({ subdomain }: Props) => {
  const { showGlobalMessage } = useContext(UIContext);

  const [state, setState] = useState<State>({ skip: true, isLoaded: false });

  const {
    data,
    error,
    loading: isServiceLoading
  } = useFindMySiteConfigurationQuery({
    variables: {
      input: {
        subdomain
      }
    },
    skip: state.skip
  });

  useEffect(() => {
    if (!subdomain) return;
    loadFromCookie();
  }, [subdomain]);

  useEffect(() => {
    !!error && errorhandler();
    !!data && handlerData();
  }, [error, data]);

  const errorhandler = () => {
    showGlobalMessage({
      message: "Se ha producido un error. Por favor, intente nuevamente."
    });
  };

  const handlerData = () => {
    const site = data?.findMySiteConfiguration?.siteConfiguration;
    setConfiguration(site);
    setState({ ...state, site, isLoaded: true });
  };

  const isCookieExpired = (date: string) => {
    const cookieDate = new Date(date);
    const now = new Date();
    return now.getTime() - cookieDate.getTime() > CACHE.site;
  }

  const mapperCookie = (cookieSite?: string) => {
    if (!cookieSite) return;
    const { site, date } = JSON.parse(cookieSite) as { site: SiteConfiguration, date: string };

    setState(isCookieExpired(date) ?
      { ...state, skip: false } :
      { ...state, site, isLoaded: true });
  };

  const loadFromCookie = () => {
    const cookies = parseCookies();
    const cookieSite = cookies[COOKIES.siteConfiguration];

    if (!cookieSite) {
      setState({ skip: false });
      return;
    }
    mapperCookie(cookieSite);
  };

  const setConfiguration = (site) => {
    const maxAge = 30 * 24 * 60 * 60;
    const cookieValue = JSON.stringify({ site, date: new Date().toISOString() });

    setCookie(null, COOKIES.siteConfiguration, cookieValue, {
      maxAge,
      path: "/"
    });

    setCookie(null, COOKIES.locale, site.locale, {
      maxAge,
      path: "/"
    });
  };

  return {
    loadConfiguration: {
      isServiceLoading,
      isCookieLoaded: state.isLoaded,
      data: state.site
    }
  };
};
