/* eslint-disable prettier/prettier */

import { Workbox } from 'workbox-window';

const useServiceWorker = () => {

    const registerSW = () => {

        if (process.env.NODE_ENV !== "production") {
            return;
        }

        if ("serviceWorker" in navigator) {
            const wb = new Workbox("/sw.js");

            wb.addEventListener('installed', event => {
                if (!event.isUpdate) {
                    console.log('Service worker installed for the first time');
                } else {
                    console.log('Service worker updated');
                }
            });

            wb.addEventListener('waiting', () => {
                wb.messageSW({ type: 'CHECK_FOR_UPDATE' });
            });

            wb.register()
                .then((registration) => {
                    console.log("Registration success", registration.scope);
                })
                .catch((err) => {
                    console.log("Registration failed", err);
                });
        }
    };

    return {
        registerSW
    };
};

export default useServiceWorker;