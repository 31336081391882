import { ApolloProvider as ApolloService } from "@apollo/client";
import { createApolloClient } from "@nilo/apollo";
import { endpointGraphql } from "@nilo/design/src/utils";

const cachePolicies = {
  ProductInPromotion: {
    keyFields: ["id", "boxRatio"]
  }
};

const client = createApolloClient(endpointGraphql, cachePolicies);

interface Props {
  children: React.ReactNode;
}

const ApolloProvider: React.FC<Props> = ({ children }) => {
  return <ApolloService client={client}>{children}</ApolloService>;
};

export default ApolloProvider;
