import {
  ApolloClient,
  InMemoryCache,
  TypePolicies,
  createHttpLink,
  from
} from "@apollo/client";
import { setContext } from "@apollo/client/link/context";
import { getToken } from "@nilo/auth/session/get-token";
import { removeTypenameFromVariables } from "@apollo/client/link/remove-typename";

const removeTypenameLink = removeTypenameFromVariables();

export function createApolloClient(
  url: string,
  typePolicies: TypePolicies = {}
) {
  const httpLink = createHttpLink({
    uri: url
  });

  const link = from([removeTypenameLink, httpLink]);

  const authLink = setContext(async (_, { headers }) => {
    const token = await getToken();

    return {
      headers: {
        ...headers,
        ...(!!token && { authorization: `Bearer ${token}` }),
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Credentials": true
      }
    };
  });

  return new ApolloClient({
    link: authLink.concat(link),
    cache: new InMemoryCache({
      typePolicies
    })
  });
}
