"use client";
import { UIWrapper, UIProvider } from "@nilo/design";
import SiteProvider from "./site";
import { localesType, I18nProvider } from "@i18n";
import FeatureProvider from "./feature";
import { useSite } from "@hooks/use-site";
import { getLocale } from "@nilo/design/src/i18n/utils";
import ApolloProvider from "./apollo";

interface Props {
  children: React.ReactNode;
}

export const Providers = ({ children }: Props) => {
  const { site } = useSite();
  const locale = (site?.locale as localesType) || getLocale();

  return (
    <ApolloProvider>
      <I18nProvider locale={locale}>
        <UIProvider>
          <UIWrapper>
            <SiteProvider>
              <FeatureProvider>{children}</FeatureProvider>
            </SiteProvider>
          </UIWrapper>
        </UIProvider>
      </I18nProvider>
    </ApolloProvider>
  );
};
